<template>
    <div class="section-cards" :class="color">
        <layout-contained :class="padding">
            <content-heading
                v-if="heading"
                :heading="heading"
                :headingColor="headingColor"
                :headingComponent="headingComponent"
                :marginBottom="content ? 'mb-6' : 'mb-0'"
                :textCentered="textCentered"
            ></content-heading>
            <content-text
                v-if="content"
                :html="content"
                :contentColor="contentColor"
                :textCentered="textCentered"
            ></content-text>
            <layout-cards :class="heading || content ? 'mt-8' : ''" v-if="cards && cards.length">
                <layout-card
                    v-for="(card, index) in cards"
                    :key="'section-cards-card-' + index"
                    :route="card.route"
                    :image="card.image"
                    :imageSet="card.imageSet"
                    :imageSizes="card.imageSizes"
                    :imageAlt="card.imageAlt"
                    :imageAspectRatio="card.imageAspectRatio"
                    :imageObjectFit="card.imageObjectFit"
                    :imageObjectPosition="card.imageObjectPosition"
                    :imageLoading="card.imageLoading"
                    :imagePriority="card.imagePriority"
                    :heading="card.heading"
                    :excerpt="card.excerpt"
                ></layout-card>
            </layout-cards>
            <div v-if="buttonText" class="mt-8" :class="!!textCentered ? 'text-center' : ''">
                <button-primary
                    :buttonColor="buttonColor || undefined"
                    :buttonRoute="buttonRoute"
                    :buttonText="buttonText"
                    :buttonClick="buttonClick"
                    :buttonClickParams="buttonClickParams"
                ></button-primary>
            </div>
            <slot name="custom"></slot>
        </layout-contained>
    </div>
</template>

<script>
export default {
    name: 'SectionCards',
    props: {
        cards: {
            required: false,
            type: Array,
            default () {
                return [];
            },
        },
        padding: {
            required: false,
            default: 'py-12 sm:py-14',
            type: String,
        },
        buttonColor: {
            required: false,
            type: String,
            default: null,
        },
        buttonRoute: {
            required: false,
            type: [String, Object],
            default: null,
        },
        buttonText: {
            required: false,
            type: String,
            default: null,
        },
        buttonClick: {
            required: false,
            type: Function,
            default: () => {},
        },
        buttonClickParams: {
            required: false,
            type: [String, Object, Array],
            default: null,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
        content: {
            required: false,
            type: String,
            default: null,
        },
        contentColor: {
            required: false,
            type: String,
            default: 'text-gray-600',
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-gray-900',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h1',
        },
        textCentered: {
            required: false,
            type: [Boolean, Number],
            default: false,
        },
    },
};
</script>
