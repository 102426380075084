<template>
    <component :is="cardComponent" class="layout-card" :class="cardClasses" :to="route">
        <div class="flex flex-1 flex-col space-y-3">
            <image-card
                v-if="image"
                :image="image"
                :imageSet="imageSet"
                :imageSizes="imageSizes"
                :imageAlt="imageAlt"
                :imageAspectRatio="imageAspectRatio"
                :imageObjectFit="imageObjectFit"
                :imageObjectPosition="imageObjectPosition"
                :imageLoading="imageLoading"
                :imagePriority="imagePriority"
            ></image-card>
            <div
                v-if="heading || excerpt"
                class="flex flex-1 flex-col space-y-2 px-4 pb-4"
                :class="!image ? 'pt-3' : ''"
            >
                <content-subheading
                    v-if="heading"
                    :heading="heading"
                ></content-subheading>
                <content-text
                    v-if="excerpt"
                    :html="excerpt"
                ></content-text>
            </div>
        </div>
    </component>
</template>

<script>
export default {
    name: 'LayoutCard',
    props: {
        route: {
            required: false,
            type: [String, Object],
            default: null,
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        excerpt: {
            required: false,
            type: String,
            default: null,
        },
        image: {
            required: false,
            type: String,
            default: null,
        },
        imageSet: {
            required: false,
            type: String,
            default: null,
        },
        imageSizes: {
            required: false,
            type: String,
            default: null,
        },
        imageAlt: {
            required: false,
            type: String,
            default: '',
        },
        imageAspectRatio: {
            required: false,
            type: String,
            default: undefined,
        },
        imageObjectFit: {
            required: false,
            type: String,
            default: undefined,
        },
        imageObjectPosition: {
            required: false,
            type: String,
            default: undefined,
        },
        imageLoading: {
            required: false,
            type: String,
            default: undefined,
        },
        imagePriority: {
            required: false,
            type: String,
            default: undefined,
        },
    },
    computed: {
        cardComponent () {
            return this.route ? 'router-link' : 'div';
        },
        cardClasses () {
            const cls = {};
            cls['col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg overflow-hidden bg-white text-center shadow'] = true;
            cls['hover:drop-shadow-md'] = !!this.route;
            return cls;
        },
    },
};
</script>
