<template>
    <img
        class="w-full bg-zinc-50"
        :class="imageClasses"
        :sizes="imageSizes"
        :srcset="imageSet"
        :src="image"
        :alt="imageAlt"
        :loading="imageLoading"
        :fetchpriority="imagePriority"
    />
</template>

<script>
export default {
    name: 'ImageCard',
    props: {
        image: {
            required: false,
            type: String,
            default: null,
        },
        imageSet: {
            required: false,
            type: String,
            default: null,
        },
        imageSizes: {
            required: false,
            type: String,
            default: null,
        },
        imageAlt: {
            required: false,
            type: String,
            default: '',
        },
        imageAspectRatio: {
            required: false,
            type: String,
            default: 'aspect-video',
        },
        imageObjectFit: {
            required: false,
            type: String,
            default: 'object-cover',
        },
        imageObjectPosition: {
            required: false,
            type: String,
            default: 'object-center',
        },
        imageLoading: {
            required: false,
            type: String,
            default: 'lazy', // eager, lazy
        },
        imagePriority: {
            required: false,
            type: String,
            default: 'low', // high, low, auto
        },
    },
    computed: {
        imageClasses () {
            const cls = {};
            cls[this.imageAspectRatio] = true;
            cls[this.imageObjectFit] = true;
            cls[this.imageObjectPosition] = true;
            return cls;
        },
    },
};
</script>
